module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ALTR","short_name":"ALTR","description":"ALTR","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/assets/images/favicon.png","include_favicon":false,"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"44e546832cca9f3099e99fdba5501d9b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"dev":true,"siteId":"2","matomoUrl":"https://altrtrade.matomo.cloud","siteUrl":"https://test.altr.trade","requireCookieConsent":true,"requireConsent":false},
    },{
      plugin: require('../plugins/gatsby-iubenda-cookie-matomo/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"lang":"en-GB","siteId":"3040367","cookiePolicyId":"67581193","banner":{"position":"bottom","acceptButtonDisplay":true,"closeButtonDisplay":false,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"rejectButtonDisplay":true,"showPurposesToggles":true,"logo":"https://altr.trade/logo.svg","acceptButtonCaptionColor":"#FFFFFF","acceptButtonColor":"#0073CE","backgroundColor":"#FFFFFF","brandBackgroundColor":"#FFFFFF","brandTextColor":"#000000","customizeButtonCaptionColor":"#4D4D4D","customizeButtonColor":"#DADADA","rejectButtonCaptionColor":"#FFFFFF","rejectButtonColor":"#0073CE","textColor":"#000000"},"askConsentAtCookiePolicyUpdate":true,"countryDetection":true,"enableLgpd":true,"enableUspr":true,"floatingPreferencesButtonDisplay":false,"lgpdAppliesGlobally":false,"perPurposeConsent":true,"whitelabel":true}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
